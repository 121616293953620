// eslint-disable-next-line import/extensions
import { German } from 'flatpickr/dist/l10n/de.js'
import md5 from 'md5'
// eslint-disable-next-line import/extensions
import { english } from 'flatpickr/dist/l10n/default.js'
import { isToday } from '@core/utils'
import type { DashboardConfigUser, DashboardLocation, DashboardSession, DashboardWorker, User } from '@/api'

const { $gettext, $ngettext } = gettext

export const setCookie = (cname: string, cvalue: string, exdays: number) => {
  const d = new Date()

  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))

  const expires = `expires=${d.toUTCString()}`

  document.cookie = `${cname}=${cvalue};${expires};path=/`
}

export const getCookie = (cname: string) => {
  const name = `${cname}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ')
      c = c.substring(1)

    if (c.indexOf(name) === 0)
      return c.substring(name.length, c.length)
  }

  return ''
}

export const getUserLocale = () => {
  let userLocale = navigator.language
  if (navigator.language.includes('-'))
    userLocale = navigator.language.split('-')[0]

  return userLocale
}

export const datePickerConfig = () => {
  let config = {}
  if (navigator.language) {
    const userLanguage = getUserLocale()
    if (userLanguage === 'de') {
      gettext.current = userLanguage
      config = { enableTime: true, dateFormat: 'Z', altInput: true, locale: German, defaultDate: new Date() }
    }
    else {
      config = { enableTime: true, dateFormat: 'Z', altInput: true, locale: english, defaultDate: new Date() }
    }
  }

  return config
}

export const convertNewlinesToBreakTags = (input: string) => {
  return input.replace(/\r?\n/g, '<br />')
}

export const resolveStatusVariantColor = (stat: string | boolean) => {
  if (stat === 'approved' || stat === 'ended' || stat === true)
    return 'success'
  if (stat === 'pending' || stat === 'running')
    return 'info'
  if (stat === false || stat === 'deactivated' || stat === 'canceled')
    return 'secondary'
  if (stat === 'rejected' || stat === 'overdue')
    return 'error'

  return 'primary'
}

export const getDisplayDateFormat = (value: string, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  const today = new Date()

  // Check if the year is different for past dates
  const isPastDate = date < today
  const isSameYear = date.getFullYear() === today.getFullYear()

  if (toTimeForCurrentDay && isToday(date))
    return ''

  if (isPastDate && !isSameYear) {
    const formatting: Record<string, string> = { day: '2-digit', month: '2-digit', year: '2-digit' }

    return new Intl.DateTimeFormat(getUserLocale(), formatting).format(date)
  }
  else {
    const formatting: Record<string, string> = { day: '2-digit', month: '2-digit' }

    return new Intl.DateTimeFormat(getUserLocale(), formatting).format(date)
  }
}

export const fullName = (input?: DashboardWorker | DashboardConfigUser) => {
  return [input?.first_name, input?.last_name].filter(Boolean).join(' ')
}

export const infoBoxSubtext = (input?: DashboardTechnician | DashboardConfigUser) => {
  return [input?.company, input?.email].filter(Boolean).join(' - ')
}
export const resolveGroupNameColorAndIcon = (groupName: string) => {
  if (groupName === 'session_read_only' || groupName === 'Readonly Sessions')
    return { color: 'warning', icon: 'tabler-user' }
  if (groupName === 'admin' || groupName === 'Admin')
    return { color: 'success', icon: 'tabler-circle-check' }
  if (groupName === 'session_only' || groupName === 'Only Sessions')
    return { color: 'info', icon: 'tabler-pencil' }

  return { color: 'primary', icon: 'tabler-user' }
}

export const fullLocation = (location?: DashboardLocation) => {
  return [location?.street, location?.postcode, location?.city].filter(Boolean).join(', ')
}

const convertBlobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onloadend = () => {
      if (typeof reader.result === 'string')
        resolve(reader.result)
      else
        reject(new Error('Error converting Blob to base64'))
    }
    reader.readAsDataURL(blob)
  })
}

export const gravatar = async (email: string | null | undefined): Promise<string> => {
  if (!email)
    return ''

  const trimmedEmail = email.trim().toLowerCase()
  const hash = md5(trimmedEmail)
  const gravatarURL = `https://www.gravatar.com/avatar/${hash}?d=404`

  try {
    const response = await fetch(gravatarURL)

    if (response.ok) {
      const blob = await response.blob()

      return await convertBlobToBase64(blob)
    }
    else {
      return ''
    }
  }
  catch (error) {
    console.error('Error fetching Gravatar image:', error)

    return ''
  }
}

export const getStatusText = (item: DashboardSession | DashboardWorker | User | DashboardLocation) => {
  // eslint-disable-next-line no-prototype-builtins
  if (item.hasOwnProperty('is_active')) {
    return item.is_active ? $gettext('Active') : $gettext('Inactive')
  }
  else {
    switch (item.status) {
      case 'ended':
        return $gettext('Finished')
      case 'canceled':
        return $gettext('Cancelled')
      case 'running':
      case 'overdue':
        const timeLeft = Math.round((new Date(item.end_at_expected) - new Date()) / 60000)

        return $ngettext('%{ timeLeft } Min', '%{ timeLeft } Mins', timeLeft, { timeLeft })
      case 'approved':
        return $gettext('Approved')
      case 'rejected':
        return $gettext('Rejected')
      case 'deactivated':
        return $gettext('Deactivated')
      case 'pending':
        return $gettext('Pending')
      default:
        return item.status
    }
  }
}
